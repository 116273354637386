/* load font */
@import url('../fonts/stylesheet.css');
@font-face {font-family: "Avenir Black";
  src: url("../fonts/275de2221d9f0c4c9257d17f5a1e4006.eot");
  src: url("../fonts/275de2221d9f0c4c9257d17f5a1e4006.eot?#iefix") format("embedded-opentype"), 
  url("../fonts/275de2221d9f0c4c9257d17f5a1e4006.woff2") format("woff2"),
  url("../fonts/275de2221d9f0c4c9257d17f5a1e4006.woff") format("woff"), 
  url("../fonts/275de2221d9f0c4c9257d17f5a1e4006.ttf") format("truetype"), 
  url("../fonts/275de2221d9f0c4c9257d17f5a1e4006.svg#Avenir Black") format("svg");
}
/* 
	font-family: 'Avenir';
	font-family:"Avenir Black" !important;
	font-family: 'Avenir LT Std';
 */
/*--------------------------------
1. Body 
-------------------------------- */
body{
	/*background: url();
	background-size: 100%;*/
	background: #fff;
	position: relative;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: bottom;
	font-family: 'Avenir';
	font-weight: 400;
	overflow-x: hidden;
}
html, body{}
html{}
a{
	text-decoration: none;
}
a:hover{
	text-decoration: none;
}
a:active{
	text-decoration: none;
}
a:focus{
	text-decoration: none;
}
a:visited{
	text-decoration: none;
}
h1, h2, h3, h4, h5, h6{
	margin: 0;
}
ul{
	margin: 0;
	padding: 0;
}
h1{
	font-size:30px;
}
h2{
	font-size:26px;
}
h3{
	font-size:24px;
}
h4{
	font-size:20px;
}
h5, h6{
	font-size:16px
}
p {
    margin: 0 0 15px;
}
::-webkit-input-placeholder{
   color: #2b2b2b;
}
:-moz-placeholder{ /* Firefox 18- */
   color: #2b2b2b;  
}
::-moz-placeholder{  /* Firefox 19+ */
   color: #2b2b2b;  
}
:-ms-input-placeholder{  
   color: #2b2b2b;  
}
.clear{
	clear: both;
}
.text-center{
	text-align: center !important;
}
.text-right{
	text-align: right !important;
}
.text-left{
	text-align: left !important;
}
/* ============================================================================== */
.wrap-main-menu-logo-top{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 40px;
	padding-bottom: 25px;
	z-index: 99;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.main-logo-top{
	position: relative;
	width: 170px;
}
.main-logo-top img{
	width: 100%;
}
.mlogoscroll{
	display: none;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.wrap-main-menu-logo-top-in{
	position: relative;
}
.wrap-main-menu-top-right{
	position: absolute;
	right: 0;
	top: 0;
	padding-top: 13px;
}
ul.main-menu-top-right{}
ul.main-menu-top-right li a:hover{
	color: #eee;
}
ul.main-menu-top-right li a{
	color: #fff;
}
ul.main-menu-top-right li.active:before{
	content:"";
	position: absolute;
	left: 0;
	bottom: -10px;
	width: 40px;
	height: 4px;
	background: #FFFFFF;
}
ul.main-menu-top-right li{
	list-style: none;
	display: inline-block;
	margin-left: 50px;
	font-weight: 400;
	font-size: 17px;
	letter-spacing: 3px;
	position: relative;
}
/* slider home */
.wrap-header-slider-home{
	position: relative;
}
.slider-main-header-home{}
.item-slider-main-home{
	position: relative;
	width: 100%;
	height: 100vh;
}
.item-slider-main-home img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.slider-home-text-info{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: table;
}
.slider-home-text-info-in{
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}
.container-slider-home-text-info{
	width: 900px;
	margin: auto;
	position: relative;
}
h2.heading-title-text-slider-home{
	font-size: 59px;
	font-family: 'Avenir LT Std';
	font-weight: 400;
	color: #FEF9F8;
	line-height: 56px;
	margin-bottom: 10px;
	letter-spacing: 4px;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
p.par-text-desc-slider-home{
	font-size: 22px;
	font-weight: 500;
	color: #fff;
	margin-bottom: 25px;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.par-text-desc-slider-home-cus-padding1{
	padding-left: 40px;
}
.par-text-desc-slider-home-cus-padding2{
	padding-left: 80px;
}
.emblem-bubu25{
	position: absolute;
	top: 20%;
	right: 8%;
	width: 90px;
	z-index: 2;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.emblem-bubu25 img{
	width: 100%;
}
h3.heading-title-text-slider-home1{
	color: #fff;
	font-weight: 600;
	font-size: 17px;
	margin-bottom: 15px;
	letter-spacing: 1px;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.snapchat-icon-slider{
	position: absolute;
	right: 0;
	top: 25%;
	width: 170px;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.snapchat-icon-slider img{
	width: 100%;
}
.info-bubu25-slide-section-logo{
	float: left;
	width: 220px;
}
.info-bubu25-slide-section-logo img{
	width: 220px;
}
.info-bubu25-slide-section-text{
	margin-left: 220px;
	padding-left: 30px;
}
.slider-main-header-home .center .heading-title-text-slider-home1{
	-webkit-animation-name: fadeInDown;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDownwn;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInDown;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInDown;
    -o-animation-duration: 1.2s;
    animation-name: fadeInDown;
    animation-duration: 1.2s;
}
.slider-main-header-home .center .heading-title-text-slider-home{
	-webkit-animation-name: fadeInDown;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDownwn;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInDown;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInDown;
    -o-animation-duration: 1.2s;
    animation-name: fadeInDown;
    animation-duration: 1.2s;
}
.slider-main-header-home .center .par-text-desc-slider-home{
	-webkit-animation-name: fadeInUp;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoUp;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInUp;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInUp;
    -o-animation-duration: 1.2s;
    animation-name: fadeInUp;
    animation-duration: 1.2s;
}
.slider-main-header-home .center .emblem-bubu25{
	-webkit-animation-name: fadeInRight;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoRight;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInRight;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInRight;
    -o-animation-duration: 1.2s;
    animation-name: fadeInRight;
    animation-duration: 1.2s;
}
.slider-main-header-home .center .snapchat-icon-slider{
	-webkit-animation-name: fadeInRight;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoRight;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInRight;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInRight;
    -o-animation-duration: 1.2s;
    animation-name: fadeInRight;
    animation-duration: 1.2s;
}
.slider-main-header-home .center .info-bubu25-slide-section-logo{
	-webkit-animation-name: fadeInLeft;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoLeft;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInLeft;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInLeft;
    -o-animation-duration: 1.2s;
    animation-name: fadeInLeft;
    animation-duration: 1.2s;
}
.bt-nav-slider-home{
	position: absolute;
	bottom: 200px;
	right: 15%;
	z-index: 3;
}
.nav-btn-slide-home i{
	font-size: 27px;
	line-height: 27px;
	top: 5px;
}
.nav-btn-slide-home{
	position: relative;
	display: inline-block;
	width: 40px;
	padding-top: 5px;
	padding-bottom: 4px;
	border: 1px solid #fff;
	color: #fff;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
}
.bt-nav-slider-home .owl-prev, .owl-next{
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}
.wrap-our-Ventures-logo{
	position: relative;
	padding-top: 70px;
	padding-bottom: 70px;
}
h2.heading-title-our-ventures{
	font-size: 60px;
	font-family: 'Avenir LT Std';
	font-weight: 400;
	text-align: center;
	margin-bottom: 40px;
}
.wrap-item-logo-our-ventures{
	position: relative;
	text-align: center;
}
.item-logo-our-ventures-in img{
	object-fit: scale-down;
	max-width: 100%;
	max-height: 100%;
	filter: grayscale(100%);
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.item-logo-our-ventures-in:hover img{
	-webkit-filter: grayscale(0%);
	-moz-filter: grayscale(0%);
	-ms-filter: grayscale(0%);
	-o-filter: grayscale(0%);
	filter: grayscale(0%);
}
.overlay-text-info-portfolio-in{
	height: 100%;
	width: 100%;
	display: table-cell;
	vertical-align: middle;
	color: #fff;
	font-size: 13px;
	font-family: 'Avenir LT Std';
	font-weight: 400;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}	
.overlay-text-info-portfolio{
	position: absolute;
	bottom: -100px;
	left: 0;
	width: 100%;
	height: 45px;
	display: table;
	background: rgba(208,55,0, 0.7);
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.item-logo-our-ventures-in:hover .overlay-text-info-portfolio{
	bottom: 0;
}
.item-logo-our-ventures-in{
	position: relative;
	width: 90%;
	display: block;
	margin: auto;
	max-height: 180px;
	overflow: hidden;
}
.item-logo-our-ventures{
	display: inline-block;
	/* padding-left: 40px;
	padding-right: 40px; */
	vertical-align: middle;
	width: 18%;
	margin-bottom: 40px;
}
.wrap-bubu-division-content{
	position: relative;
}
.item-bubu-division-full{
	position: relative;
}
.item-bubu-division-full-logo{
	position: relative;
	height: 100vh;
	float: left;
	width: 30%;
	display: table;
}
.item-bubu-division-full-logo-icon{
	display: table-cell;
	vertical-align: middle;
}
.item-bubu-division-full-desc{
	position: relative;
	height: 100vh;
	float: left;
	width: 70%;
	display: table;
	overflow: hidden;
}
.item-bubu-division-full-logo-icon-in img{
	max-width: 100%;
	object-fit: scale-down;
	display: inline-block;
}
.item-bubu-division-full-logo-icon-in{
	width: 75%;
	margin:auto;
	display: block;
}
.item-bubu-division-full-desc-table-cell{
	display: table-cell;
	vertical-align: middle;
}
.container-item-bubu-division-full-desc{
	width: 70%;
	margin: auto;
	position: relative;
}
h1.heading-title-bubu-division-full{
	color: #4A4A4A;
	font-family: 'Avenir LT Std';
	font-size: 59px;
	font-weight: 400;
	margin-bottom: 10px;
}
h4.heading-subtitle-bubu-division-full{
	color: #4A4A4A;
	font-size: 19px;
	font-weight: 400;
	font-family: 'Avenir LT Std';
	margin-bottom: 20px;
	line-height: 30px;
	letter-spacing: 1px;
}
.content-item-scroll-division-info-padding{
	padding-left: 140px;
}
.content-item-scroll-division-info-in{
	padding-right: 140px;
}
.content-item-scroll-division-info{
	position: relative;
	height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
}
.content-item-scroll-division-info > h4.heading-subtitle-bubu-division-full{
	width: 80%;
}
.wrap-bt-explore-bubu-division{
	position: relative;
	padding-top: 30px;
	padding-left: 140px;
}
a.bt-explore-bubu-division{
	font-size: 9px;
	color: #fff;
	font-family: 'Avenir LT Std';
	display: inline-block;
	width: 110px;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 20px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: bold;
}
.bg-division-info-full{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.bg-division-info-full img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.item-bubu-division-full-bubu .item-bubu-division-full-logo{
	background: #F26522;
}
.item-bubu-division-full-bubu a.bt-explore-bubu-division:hover{
	background: #c7511b;
}
.item-bubu-division-full-bubu a.bt-explore-bubu-division{
	background: #F26522;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.item-bubu-division-full-startup .item-bubu-division-full-logo{
	background: #FF0000;
}
.item-bubu-division-full-startup a.bt-explore-bubu-division:hover{
	background: #cc0000;
}
.item-bubu-division-full-startup a.bt-explore-bubu-division{
	background: #FF0000;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.item-bubu-division-full-bubugaming .item-bubu-division-full-logo{
	background: #FA8223;
}
.item-bubu-division-full-bubugaming a.bt-explore-bubu-division:hover{
	background: #af5c18;
}
.item-bubu-division-full-bubugaming a.bt-explore-bubu-division{
	background: #FA8223;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.item-bubu-division-full-labx .item-bubu-division-full-logo{
	background: #000;
}
.item-bubu-division-full-labx a.bt-explore-bubu-division:hover{
	background: rgb(15, 15, 15);
}
.item-bubu-division-full-labx a.bt-explore-bubu-division{
	background: #000;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}

.item-supergirl-division-full .item-bubu-division-full-logo{
	background: #fff;
}
.item-supergirl-division-full a.bt-explore-bubu-division{
	background: #fff;
	color: #FF7B09;
}



.wrap-term-of-service-sec{
	position: relative;
	padding-top: 40px;
	padding-bottom: 90px;
	background: #fff;
	text-align: center;
}
.link-term-of-service-privacy-policy{
	position: relative;
	margin-bottom: 10px;
}
a.bt-term-of-service-privacy-policy:hover{
	color: #F26522;
}
a.bt-term-of-service-privacy-policy{
	display: inline-block;
	margin-left: 15px;
	margin-right: 15px;
	font-size: 20px;
	font-weight: 500;
	color: #A5A5A5;	
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
h4.bubutagmeta{
	font-size: 20px;
	font-weight: 500;
	color: #A5A5A5;
}


.footer-bar-bot{
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 90px;
	padding-right: 90px;
	background: #000;
}
.copyright-bar-bot{
	font-size: 16px;
	color: #fff;
	font-weight: 500;
}
ul.social-media-footer{
	text-align: right;
}
ul.social-media-footer li a:hover{
	color: #f26522;
	border: 1px solid #f26522;
}
ul.social-media-footer li a{
	color: #fff;
	height: 34px;
	width: 34px;
	border: 1px solid #fff;
	padding-top: 7px;
	display: inline-block;
	text-align: center;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
ul.social-media-footer li{
	list-style: none;
	display: inline-block;
	font-size: 17px;
	line-height: 20px;
	margin-left: 4px;
}
.wrap-social-media-footer{
	position: absolute;
	top: -5px;
	right: 0px;
}
.footer-bar-bot-in{
	position: relative;
}
.content-item-scroll-division-info::-webkit-scrollbar {
	width: 5px;
} 
/* Track */
.content-item-scroll-division-info::-webkit-scrollbar-track {
	background: #707070; 
}  
/* Handle */
.content-item-scroll-division-info::-webkit-scrollbar-thumb {
background: #FF5818; 
} 
/* Handle on hover */
.content-item-scroll-division-info::-webkit-scrollbar-thumb:hover {
	background: #eb5216; 
}
/* menu scroll */
.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active{
	background: #fff;
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #eee;
}
.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active .mlogonormal{
	display: none;
}
.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active .mlogoscroll{
	display: block;
}
.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active ul.main-menu-top-right li.active:before{
	background: #FB7B26;
}
.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active ul.main-menu-top-right li a:hover{
	color: #FB7B26;
}
.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active ul.main-menu-top-right li a{
	color: #2A2A2A;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active .bt-menu-burger-mobile{
	color: #2A2A2A;
}
.bt-menu-burger-mobile{
	color: #fff;
	font-size: 28px;
	position: absolute;
	right: 0;
	top: -3px;
	cursor: pointer;
	display: none;
}



.wrap-menu-responsive-active{
	right: 0 !important;
}
.wrap-menu-responsive{
	position: fixed;
	top: 0;
	right: -100%;
	width: 320px;
	height: 100vh;
	background: #fff;
	z-index: 999;
	padding-top: 30px;
	padding-bottom: 30px;
	-webkit-transition: 0.7s;
	-o-transition: 0.7s;
	transition: 0.7s;
}
.bt-close-menu-res{
	text-align: right;
	font-size: 30px;
	color: #000;
	padding-right: 20px;
	margin-bottom: 20px;
}
ul.main-menu-responsive{
	text-align: right;
}
ul.main-menu-responsive li a{
	color: #000;
}
ul.main-menu-responsive li.active:before{
	content: "";
	width: 80px;
	height: 2px;
	background: #f05221;
	position: absolute;
	right: 20px;
	bottom: 0;
}
body.active-menu:before{
	content: "";
	background: rgba(0,0,0, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	-webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.7s;
    -moz-animation-name: fadeIn;
    -moz-animation-duration: 0.7s;
    -ms-animation-name: fadeIn;
    -ms-animation-duration: 0.7s;
    -o-animation-name: fadeIn;
    -o-animation-duration: 0.7s;
    animation-name: fadeIn;
    animation-duration: 0.7s;
}
ul.main-menu-responsive li{
	position: relative;
	list-style: none;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 800;
	padding-right: 20px;
	padding-left: 20px;
	letter-spacing: 1px;
}

body.active-menu:before{
	content: "";
	background: rgba(0,0,0, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}
.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed .mlogonormal{
	display: none;
}
.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed .mlogoscroll{
	display: block !important;
}
.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed{
	background: #fff;
	padding-top: 40px;
	padding-bottom: 40px;
}
.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed ul.main-menu-top-right li.active:before{
	background: #FB7B26;
}
.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed ul.main-menu-top-right li a:hover{
	color: #FB7B26;
}
.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed ul.main-menu-top-right li a{
	color: #2A2A2A;
}
.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed .bt-menu-burger-mobile{
	color: #2A2A2A;
}
.wrap-section-people-slider-full{
	position: relative;
}
.section-people-slider-full{}
.item-slider-people-info-section{
	/* position: relative; */
}
.item-slider-people-info-section-left{
	float: left;
	width: 35%;
	height: 750px;
	background: #F26522;
	display: table;
}
.item-slider-people-info-section-left-in{
	display: table-cell;
	vertical-align: middle;
}
.item-slider-people-info-section-right{
	float: left;
	width: 65%;
	height: 750px;
	background: #fff;
	display: table;
}
.item-slider-people-info-section-right-in{
	display: table-cell;
	vertical-align: middle;
}
.wrap-text-slider-people-tag-title{
	position: relative;
	width: 80%;
	margin: auto;
}
h1.text-slider-people-tag-title{
	font-size: 54px;
	font-weight: 500;
	font-family: 'Avenir LT Std';
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 1px;
}
.container-item-slider-people-info-sec-right-c{
	width: 60%;
	position: relative;
	margin: auto;
}
h2.heading-title-name-of-poeple-s{
	font-size: 50px;
	color: #FC4500;
	font-weight: 500;
	font-family: 'Avenir LT Std';
	text-transform: uppercase;
	letter-spacing: 1px;
}
h4.subtitle-name-of-people-s{
	font-size: 21px;
	color: #4A4A4A;
	font-family: 'Avenir LT Std';
	font-weight: 500;
	position: relative;
	margin-top: -6px;
	letter-spacing: 1px;
}
.content-par-people-desc-sec-in p{
	color: #4A4A4A;
	font-size: 19px;
	font-weight: 500;
	line-height: 27px;
}
.content-par-people-desc-sec-in{
	padding-left: 150px;
	padding-right: 120px;
	/* width: 600px; */
}
.content-par-people-desc-sec{
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	height: 150px;
	margin-top: 30px;
}
.people-avatar-img-full.people-avatar-img-full2{
	width: 34%;
	left: 23%;
}
.people-avatar-img-full{
	position: absolute;
	left: 27%;
	bottom: 0;
	width: 27%;
	z-index: 5;
}
.people-avatar-img-full img{
	width: 100%;
}
.people-section{
	overflow: hidden;
	background: #fff;
}
.bt-nav-slider-home2{
	position: absolute !important;
	bottom: 10% !important;
	right: 15% !important;
	z-index: 3 !important;
}

.nav-btn-slide-home2 i{
	font-size: 23px;
	line-height: 23px;
}
.nav-btn-slide-home2{
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	padding-top: 1px;
	border: 1px solid #000;
	color: #000;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
}



.scrollbar-inner > .scroll-element.scroll-y{
	height: 100%;
    right: 2px;
    top: 0;
    width: 18px !important;
}
.scrollbar-inner > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}
.scrollbar-inner>.scroll-element.scroll-y .scroll-element_track {
    left: 7px;
    width: 2px;
	background: #707070;
}
.scrollbar-inner .scroll-bar {
    background: #FF5818 !important;
    width: 16px !important;
    height: 16px !important;
    opacity: 1 !important;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.scrollbar-inner > .scroll-element.scroll-x{
	display: none;
}


.section-people-slider-full .center .item-slider-people-info-section-left{
	-webkit-animation-name: fadeInLeft;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoLeft;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInLeft;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInLeft;
    -o-animation-duration: 1.2s;
    animation-name: fadeInLeft;
    animation-duration: 1.2s;
}
.section-people-slider-full .center .text-slider-people-tag-title{
	-webkit-animation-name: fadeInLeft;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoLeft;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInLeft;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInLeft;
    -o-animation-duration: 1.2s;
    animation-name: fadeInLeft;
    animation-duration: 1.2s;
}
.section-people-slider-full .center .container-item-slider-people-info-sec-right-c{
	-webkit-animation-name: fadeInRight;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoRight;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInRight;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInRight;
    -o-animation-duration: 1.2s;
    animation-name: fadeInRight;
    animation-duration: 1.2s;
}
.section-people-slider-full .center .people-avatar-img-full{
	-webkit-animation-name: slideInUp;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: faslideDoUp;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: slideInUp;
    -ms-animation-duration: 1.2s;
    -o-animation-name: slideInUp;
    -o-animation-duration: 1.2s;
    animation-name: slideInUp;
    animation-duration: 1.2s;
}
.wrap-section-contact-us-full{
	position: relative;
	padding-top: 170px;
}
.container-contact-us-section{
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
}
h2.heading-title-contact-us-form{
	position: relative;
	font-size: 45px;
	color: #FC4500;
	font-weight: 400;
	font-family: 'Avenir LT Std';
	letter-spacing: 2px;
	margin-bottom: 30px;
}
.padding-contac-us-form-sec{
	position: relative;
	padding-left: 60px;
}
.section-text-contact-info-detail{
	position: relative;
	margin-bottom: 60px;
}
.info-desc-sec-text-contact-us{
	position: relative;
	max-width: 400px;
	width: 100%;
}
.info-desc-sec-text-contact-us h4{
	color: #4A4A4A;
	font-weight: 500;
	font-size: 19px;
	line-height: 27px;
}
.contact-info-email-sec{
	position: relative;
	padding-top: 20px;
}
.contact-info-email-sec h2{
	position: relative;
	font-size: 45px;
	color: #FC4500;
	font-weight: 400;
	font-family: 'Avenir LT Std';
	letter-spacing: 2px;
}
.contact-info-email-sec h4{
	position: relative;
	font-size: 18px;
	color: #707070;
	font-weight: 600;
	font-family: 'Avenir LT Std';
}
.custom-padding-contact .col-lg-6, .col-lg-12{
	padding-left: 90px;
	padding-right: 90px;
}
.section-contact-form-connect{
	position: relative;
}
.wrap-input-form-connect-with-us{
	position: relative;
	margin-bottom: 60px;
}
.item-input-form-connect-with-us{
	position: relative;
	margin-bottom: 20px;
}
.item-input-form-connect-with-us-title:before{
	content:":";
	position: absolute;
	right: 0;
	top: 0;
	color: #FC4500;
	font-size: 22px;
}
.item-input-form-connect-with-us-title{
	float: left;
	width: 160px;
	font-size: 18px;
	font-weight: bold;
	color: #707070;
	font-family: 'Avenir LT Std';
	position: relative;
	padding-top: 5px;
}
.item-input-form-connect-with-us-form{
	position: relative;
	margin-left: 160px;
	padding-left: 30px;
}
input.input-f-connect-us-i{
	width: 200px;
	font-size: 16px;
	outline: none;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 7px;
	border: none;
	border-bottom: 1px solid #FC4500;
}
.textarea-f-connect-us-i{
	width: 500px;
	height: 120px;
	resize: none;
	outline: none;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 7px;
	border: none;
	border-bottom: 1px solid #FC4500;
	font-size: 16px;
}
.wrap-bt-submit-connect-us{
	position: relative;
	margin-top: 40px;
}
.bt-submit-connect-us-cta:hover{
	background: #d6681e;
}
.bt-submit-connect-us-cta{
	width: 120px;
	text-align: center;
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 12px;
	background: #FB7B26;
	border-radius: 20px;
	color: #fff;
	outline: none;
	border: none;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.wrap-tab-section-menu-left-media{
	position: relative;
	float: left;
	width: 25%;
}
.wrap-tab-content-section-menu-right-media{
	position: relative;
	float: left;
	width: 75%;
}
.wrap-sec-media-page-bubu{
	position: relative;
	clear: both;
}
.wrap-tab-section-menu-left-media .nav{
	display: block;
}
.wrap-tab-section-menu-left-media .nav li{
	display: block;
}
.wrap-tab-section-menu-left-media .nav-item{
	margin-bottom: 35px;
}
.wrap-tab-section-menu-left-media .nav-link{
	position: relative;
	padding: 0;
}
h3.title-heading-page-media-tab{
	font-size: 24px;
	font-weight: 400;
	color: #F26522;
	font-family: 'Avenir LT Std';
	margin-bottom: 60px;
}
.item-tab-content-media-sec-active{
	display: block !important;
	-webkit-animation-name: fadeInUp;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInUp;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInUp;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInUp;
    -o-animation-duration: 1.2s;
    animation-name: fadeInUp;
    animation-duration: 1.2s;
}
.item-tab-content-media-sec{
	display: none;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.item-tab-media-content li.active a{
	color: #F26522;
}
.item-tab-media-content li a{
	color: #707070;
}
.item-tab-media-content li{
	list-style: none;
	margin-bottom: 35px;
	font-size: 19px;
	color: #707070;
	text-transform: uppercase;
}
.item-media-box-square{
	width: 30%;
}
.item-media-box-rectangle{
	width: 69.111%;
}
.item-media-box-rectangle-square{
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding-left: 20px;
	padding-bottom: 20px;
}
.item-media-box-rectangle-square-in:hover img{
	-webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.item-media-box-rectangle-square-in{
	position: relative;
	width: 100%;
	height: 300px;
	overflow: hidden;
}
.item-media-box-rectangle-square-in img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.overlay-info-media-text-bar{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: rgba(0,0,0, 0.6);
	height: 90px;
	display: table;
}
.overlay-info-media-text-bar-in{
	position: relative;
	display: table-cell;
	vertical-align: middle;
}
.item-media-box-square h3.title-info-media-text-bar{
	width: 70%;
}
h3.title-info-media-text-bar{
	width: 50%;
	padding-left: 25px;
	font-size: 18px;
	color: #fff;
	font-family: 'Avenir LT Std';
	font-weight: 400;
}
.info-media-text-by{
	position: absolute;
	right: 0;
	top: 0;
	display: table;
	width: 40%;
	height: 100%;
}
.info-media-text-by-in{
	display: table-cell;
	vertical-align: middle;
	text-align: right;
	padding-right: 25px;
	font-size: 20px;
	color: #fff;
	font-family: 'Avenir LT Std';
	font-weight: 400;
}
.wrap-bt-show-more-media-box{
	position: relative;
	padding-top: 60px;
}
a.bt-show-more-media-box:hover{
	background: #c9621e;
}
a.bt-show-more-media-box{
	display: block;
	width: 120px;
	color: #fff;
	margin: auto;
	text-align: center;
	background: #FB7B26;
	font-size: 12px;
	font-weight: 400;
	font-family: 'Avenir LT Std';
	border-radius: 20px;
	padding-top: 12px;
	padding-bottom: 12px;
	-webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.wrap-tab-media-content-section{
	position: relative;
	padding-bottom: 80px;
}
.tab-mobile-media-item{
	display: none;
	width: 100%;
	border-radius: 7px;
	padding-top: 6px;
	padding-bottom: 6px;
	border: 1px solid #eee;
	outline: none;
	padding-left: 15px;
	padding-right: 15px;
	text-transform: uppercase;
}
.content-item-scroll-division-info::-webkit-scrollbar {
	width: 5px;
} 
/* Track */
.content-item-scroll-division-info::-webkit-scrollbar-track {
	background: #707070; 
}  
/* Handle */
.content-item-scroll-division-info::-webkit-scrollbar-thumb {
background: #FF5818; 
} 
/* Handle on hover */
.content-item-scroll-division-info::-webkit-scrollbar-thumb:hover {
	background: #eb5216; 
}




.content-par-people-desc-sec::-webkit-scrollbar {
	width: 5px;
} 
/* Track */
.content-par-people-desc-sec::-webkit-scrollbar-track {
	background: #707070; 
}  
/* Handle */
.content-par-people-desc-sec::-webkit-scrollbar-thumb {
background: #FF5818; 
} 
/* Handle on hover */
.content-par-people-desc-sec::-webkit-scrollbar-thumb:hover {
	background: #eb5216; 
}

.social-media-per-division{
	position: absolute;
	bottom: 0px;
	right: 0;
}
ul.social-media-per-division-item{}
.item-bubu-division-full-bubu ul.social-media-per-division-item li a:hover{
	color: #F26522;
	border-color: #F26522;
}
.item-bubu-division-full-startup ul.social-media-per-division-item li a:hover{
	color: #FF0000;
	border-color: #FF0000;
}
.item-bubu-division-full-bubugaming ul.social-media-per-division-item li a:hover{
	color: #FA8223;
	border-color: #FA8223;
}
.item-bubu-division-full-labx ul.social-media-per-division-item li a:hover{
	color: #000;
	border-color: #000;
}
.item-supergirl-division-full ul.social-media-per-division-item li a:hover{
	color: #000;
	border-color: #000;
}
ul.social-media-per-division-item li a{
	color: #4A4A4A;
    height: 34px;
    width: 34px;
    border: 1px solid #4A4A4A;
    padding-top: 7px;
    display: inline-block;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
ul.social-media-per-division-item li{
	list-style: none;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    margin-left: 4px;
}
.box-modal-form-info-left{
	float: left;
	width: 30%;
	height: 620px;
	background: url('../images/bg-bub25popreg.png')no-repeat;
	background-size: cover;
	padding-top: 20px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 30px;
}
.box-modal-form-info-right{
	float: right;
	background: #fff;
	width: 70%;
	height: 620px;
	padding-top: 50px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 30px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}
.box-modal-form-info-left-in{
	position: relative;
	padding-top: 110px;
}
.img-logo-form-bubu25{
	width: 100%;
	margin-bottom: 40px;
}
.img-logo-form-bubu25 img{
	width: 100%;
}
.custom-form-modal-popup .modal-content{
	background:transparent;
	border: none;
}
.custom-form-modal-popup .modal-body{
	padding: 0;
}
h1.big-title-popup-bubu25-info{
	font-family: 'Avenir LT Std';
	font-size: 25px;
	color: #fff;
	letter-spacing: 3px;
	font-weight: 900;
}
.item-form-input-bubu25-reg-info{
	position: relative;
	margin-bottom: 15px;
}
h4.head-title-name-input-r{
	font-size: 16px;
	color: #1D1D1D;
	letter-spacing: 2px;
	margin-bottom: 4px;
}
.item-input-t-name-reg-f{
	position: relative;
	width: 80%;
}
.i-input-t-name-r{
	width: 100%;
	outline: none;
	border: none;
	border-bottom: 2px solid #FF6F00;
	color: #1D1D1D;
	background: transparent;
	font-size: 15px;
}
.i-input-t-name-r2{
	width: 100%;
	outline: none;
	border: none;
	border-bottom: 2px solid #FF6F00;
	color: #1D1D1D;
	background: transparent;
	font-size: 15px;
	cursor: pointer;
}
.bt-close-modal-reg-bubu25{
	position: absolute;
	right: 20px;
	top: 20px;
	width: 16px;
}
.bt-close-modal-reg-bubu25 img{
	width: 100%;
	cursor: pointer;
}
.wrap-bt-submit-form-reg-bubu25-p{
	position: relative;
	text-align: right;
	margin-top: 50px;
}
.bt-submit-form-reg-bubu25-p{
	display: inline-block;
	width: 140px;
	text-align: center;
	background: #FF7600;
	color: #fff;
	font-size: 16px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 20px;
	outline: none;
	border: none;
}
.bubu-celebration-bg{
	position: relative;
	width: 100%;
	height: 100%;
	background: #F26522;
}
.container-cus-celebration-bubu{
	position: relative;
	width: 75%;
	margin: auto;
	padding-top: 190px;
}
.item-bubu-celeb-logo{
	float: left;
	width: 150px;
}
.item-bubu-celeb-logo img{
	width: 100%;
}
.heading-cus-celebration-bubu{
	position: relative;
	clear: both;
	margin-bottom: 10px;
}
.item-bubu-celeb-text{
	margin-left: 150px;
	padding-left: 30px;
}
h2.heading-title-bubu-celebration-txt{
	color: #fff;
	font-weight: bold;
	font-size: 50px;
	letter-spacing: 3px;
	margin-bottom: 7px;
	text-transform: uppercase;
}
h4.heading-title-bubu-celebration-txt2{
	color: #fff;
	font-weight: 600;
	font-size: 20px;
	letter-spacing: 3px;
}
h4.desc-bubu25-celebration{
	font-size: 20px;
	font-weight: 400;
	color: #fff;
	letter-spacing: 1px;
	margin-bottom: 25px;
}
a.bt-re-connect-bubu25:hover{
	background: #000;
	color: #fff;
}
a.bt-re-connect-bubu25{
	font-size: 16px;
	color: #F26522;
	font-weight: 400;
	background: #fff;
	display: inline-block;
	text-align: center;
	padding-top: 8px;
	padding-bottom: 6px;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 20px;
	text-transform: uppercase;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}
.emblem-bubu25-society{
	position: absolute;
	right: 100px;
	top: 150px;
	width: 180px;
	z-index: 2;
}
.emblem-bubu25-society img{
	width: 100%;
}
.bubu25-society-list{
	position: absolute;
	bottom: 30px;
	width: 90%;
	left: 0;
	right: 0;
	margin: auto;
}
.bubu25-society-list img{
	width: 100%;
}

.slider-main-header-home .center .heading-cus-celebration-bubu {
    -webkit-animation-name: fadeInDown;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDownwn;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInDown;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInDown;
    -o-animation-duration: 1.2s;
    animation-name: fadeInDown;
    animation-duration: 1.2s;
}
.slider-main-header-home .center h4.desc-bubu25-celebration {
    -webkit-animation-name: fadeInUp;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoUp;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInUp;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInUp;
    -o-animation-duration: 1.2s;
    animation-name: fadeInUp;
    animation-duration: 1.2s;
}
.slider-main-header-home .center a.bt-re-connect-bubu25 {
    -webkit-animation-name: fadeInUp;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoUp;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInUp;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInUp;
    -o-animation-duration: 1.2s;
    animation-name: fadeInUp;
    animation-duration: 1.2s;
}
.slider-main-header-home .center .emblem-bubu25-society {
    -webkit-animation-name: fadeInRight;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoRight;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInRight;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInRight;
    -o-animation-duration: 1.2s;
    animation-name: fadeInRight;
    animation-duration: 1.2s;
}
.slider-main-header-home .center .bubu25-society-list {
    -webkit-animation-name: fadeInUp;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fadeInDoUp;
    -moz-animation-duration: 1.2s;
    -ms-animation-name: fadeInUp;
    -ms-animation-duration: 1.2s;
    -o-animation-name: fadeInUp;
    -o-animation-duration: 1.2s;
    animation-name: fadeInUp;
    animation-duration: 1.2s;
}
.img-emblem-bubu-pop{
	position: absolute;
	left: -20px;
	top: 0;
	width: 50px;
}
.img-emblem-bubu-pop img{
	width: 100%;
}

/* additional */
.concave-image-ozil {
	position: absolute;
	right: -100px;
	bottom: 0;
	height: 50%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.concave-image-ozil img {
	object-fit: contain;
	max-height: max-content;
}


.compass-image-side {
	position: absolute;
	right: 0px;
	top: 0;
	height: 100%;
	object-fit: contain;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.compass-image-logo-6 {
	position: absolute;
	left: -5%;
	bottom: -5%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 25%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2
}

.compass-image-logo-2 {
	position: absolute;
	left: 5%;
	top: 17%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 50%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2
}

.compass-image-logo-3 {
	position: absolute;
	left: 5%;
	top: 20%;
	/* transform: translateX(-20%), translateY(-30%); */
	width: 23%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2
}

.compass-image-logo-4 {
	position: absolute;
	left: 20%;
	top: 20%;
	/* transform: translateX(-20%), translateY(-30%); */
	width: 23%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2
}

.compass-image-logo-5 {
	position: absolute;
	left: 35%;
	top: 20%;
	/* transform: translateX(-20%), translateY(-30%); */
	width: 23%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2
}

.concave-image-logo-1 {
	position: absolute;
	left: 3.5%;
	top: 15%;
	width: 20%;
	min-width: 100px;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2
}

.concave-image-logo-2 {
	position: absolute;
	left: 3.5%;
	top: 21%;
	width: 50%;
	min-width: 200px;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2
}

.compass-image-logo-1 {
	position: absolute;
	right: 10px;
	top: 15%;
	width: 10%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.concave-image-logo-3{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.concave-image-ozil img {
	width: 100%;
}
.compass-image-side img {
	width: 100%;
}
.concave-image-logo-1 img {
	width: 100%;
}
.concave-image-logo-2 img {
	width: 100%;
}
.concave-image-logo-3 img {
	width: 100%;
}
.compass-image-logo-1 img {
	width: 100%;
}
.compass-image-logo-2 img {
	width: 100%;
}
.compass-image-logo-3 img {
	width: 100%;
}
.compass-image-logo-4 img {
	width: 100%;
}
.compass-image-logo-5 img {
	width: 100%;
}
.compass-image-logo-6 img {
	width: 50%;
}

.slider-main-header-home .center .concave-image-ozil {
	-webkit-animation-name: fadeInRight;
		-webkit-animation-duration: 3.2s;
		-moz-animation-name: fadeInDoRight;
		-moz-animation-duration: 3.2s;
		-ms-animation-name: fadeInRight;
		-ms-animation-duration: 3.2s;
		-o-animation-name: fadeInRight;
		-o-animation-duration: 3.2s;
		animation-name: fadeInRight;
		animation-duration: 3.2s;
}

.slider-main-header-home .center .compass-image-side {
	-webkit-animation-name: fadeInRight;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDoRight;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInRight;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInRight;
	-o-animation-duration: 2.2s;
	animation-name: fadeInRight;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .concave-image-logo-1 {
	-webkit-animation-name: fadeInDown;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDown;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInDown;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInDown;
	-o-animation-duration: 2.2s;
	animation-name: fadeInDown;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .compass-image-logo-1 {
	-webkit-animation-name: fadeInDown;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDown;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInDown;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInDown;
	-o-animation-duration: 2.2s;
	animation-name: fadeInDown;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .compass-image-logo-2 {
	-webkit-animation-name: fadeInDown;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDown;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInDown;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInDown;
	-o-animation-duration: 2.2s;
	animation-name: fadeInDown;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .compass-image-logo-3 {
	-webkit-animation-name: fadeInLeft;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInLeft;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInLeft;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInLeft;
	-o-animation-duration: 2.2s;
	animation-name: fadeInLeft;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .compass-image-logo-4 {
	-webkit-animation-name: fadeInRight;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInRight;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInRight;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInRight;
	-o-animation-duration: 2.2s;
	animation-name: fadeInRight;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .compass-image-logo-5 {
	-webkit-animation-name: fadeInLeft;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInLeft;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInLeft;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInLeft;
	-o-animation-duration: 2.2s;
	animation-name: fadeInLeft;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .compass-image-logo-6 {
	-webkit-animation-name: spin;
	-webkit-animation-duration: 5000ms;
	-moz-animation-name: spin;
	-moz-animation-duration: 5000ms;
	-ms-animation-name: spin;
	-ms-animation-duration: 5000ms;
	-o-animation-name: spin;
	-o-animation-duration: 5000ms;
	animation-name: spin;
	animation-duration: 30000ms;
	animation-iteration-count: infinite;
}

.slider-main-header-home .center .concave-image-logo-2 {
	-webkit-animation-name: fadeInDown;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDown;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInDown;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInDown;
	-o-animation-duration: 2.2s;
	animation-name: fadeInDown;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .concave-image-logo-3 {
	-webkit-animation-name: fadeInUp;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInUpwn;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInUp;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInUp;
	-o-animation-duration: 2.2s;
	animation-name: fadeInUp;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .concave-text-1, .slider-main-header-home .center .concave-text-2 {
	-webkit-animation-name: fadeInUp;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInUpwn;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInUp;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInUp;
	-o-animation-duration: 2.2s;
	animation-name: fadeInUp;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .ggg-logo {
	-webkit-animation-name: fadeInDown;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDown;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInDown;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInDown;
	-o-animation-duration: 2.2s;
	animation-name: fadeInDown;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .ggg-main {
	-webkit-animation-name: fadeInLeft;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInLeft;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInLeft;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInLeft;
	-o-animation-duration: 2.2s;
	animation-name: fadeInLeft;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .ggg-embrasing {
	-webkit-animation-name: fadeInDown;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDown;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInDown;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInDown;
	-o-animation-duration: 2.2s;
	animation-name: fadeInDown;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .ggg-karakter {
	-webkit-animation-name: fadeInRight;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInRight;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInRight;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInRight;
	-o-animation-duration: 2.2s;
	animation-name: fadeInRight;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .ggg-text-1, .slider-main-header-home .center .ggg-text-2 {
	-webkit-animation-name: fadeInUp;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInUp;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInUp;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInUp;
	-o-animation-duration: 2.2s;
	animation-name: fadeInUp;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .compass-text-1,
.slider-main-header-home .center .compass-text-2 {
	-webkit-animation-name: fadeInUp;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInUp;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInUp;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInUp;
	-o-animation-duration: 2.2s;
	animation-name: fadeInUp;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .bubu-title, .slider-main-header-home .center .bubu-subtitle, .slider-main-header-home .center .bubu-desc {
	-webkit-animation-name: fadeInUp;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInUp;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInUp;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInUp;
	-o-animation-duration: 2.2s;
	animation-name: fadeInUp;
	animation-duration: 2.2s;
}

.slider-main-header-home .center .bubu-logo {
	-webkit-animation-name: fadeInDown;
	-webkit-animation-duration: 2.2s;
	-moz-animation-name: fadeInDown;
	-moz-animation-duration: 2.2s;
	-ms-animation-name: fadeInDown;
	-ms-animation-duration: 2.2s;
	-o-animation-name: fadeInDown;
	-o-animation-duration: 2.2s;
	animation-name: fadeInDown;
	animation-duration: 2.2s;
}

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(0,0,0, 0.5);
}

.concave-text-1 {
	position: absolute;
	left: 4%;
	bottom: 18%;
	z-index: 2;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 80%;
	font-size: 14px;
	/* font-weight: 700; */
	color: #fff;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.concave-text-2 {
	position: absolute;
	left: 4%;
	bottom: 20%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 80%;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.compass-text-1 {
	position: absolute;
	left: 4%;
	bottom: 16%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 90%;
	font-size: 14px;
	/* font-weight: 700; */
	color: #fff;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index:2
}

.compass-text-2 {
	position: absolute;
	left: 4%;
	bottom: 15%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 80%;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}


.ggg-main {
	position: absolute;
	left: -50px;
	bottom: 0;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 100%;
	height: 40%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2;
	opacity: 0.8;
}

.ggg-main img {
	object-fit: contain;
	max-height: max-content;
}

.ggg-logo {
	position: absolute;
	left: 4%;
	top: 20%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 20%;
	min-width: 150px;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.ggg-embrasing {
	position: absolute;
	right: 10%;
	top: 30%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 60%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 3;
}

.ggg-karakter {
	filter: grayscale(100%) contrast(1);
	opacity: 0.7;
	position: absolute;
	right: 0;
	bottom: 0;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 50%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 1;
}

.ggg-text-1 {
	text-align: right;
	position: absolute;
	right: 10%;
	bottom:28%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 80%;
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 4;
}

.ggg-text-2 {
	text-align: right;
	position: absolute;
	right: 10%;
	bottom: 27%;
	/* transform: translateX(-15%), translateY(-15%); */
	width: 80%;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 4;
}

.bubu-logo {
	position: absolute;
	width: 10%;
	/* max-width: 250px; */
	min-width: 150px;
	left: 10%;
	top: 18%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2;

}

.bubu-title {
	position: absolute;
	max-width: 350px;
	width: 70%;
	left: 10%;
	top: 37%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2;

}

.bubu-subtitle {
	position: absolute;
	max-width: 600px;
	width: 80%;
	left: 10%;
	top: 45%;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2;

}

.bubu-desc {
	position: absolute;
	width: 80%;
	left: 10%;
	color: #fff;
	top: 50%;
	font-weight: 400;
	font-size: 13px;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 2;

}


@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}