.owl-nav{
	position: absolute;
	bottom: 200px;
	right: 15%;
	z-index: 3;
}

.owl-prev, .owl-next{
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}

ul.main-menu-top-right li a:not([href]):not([tabindex]){
	color: #fff;
}

.wrap-main-menu-logo-top-home.wrap-main-menu-logo-top-home-active ul.main-menu-top-right li a:not([href]):not([tabindex]){
	color: #2A2A2A;
	-webkit-transition:0.3s;
    -moz-transition:0.3s;
    -ms-transition:0.3s;
    -o-transition:0.3s;
    transition:0.3s;
}