@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Light.woff2') format('woff2'),
        url('Avenir-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Roman.woff2') format('woff2'),
        url('Avenir-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('Avenir-Book.woff2') format('woff2'),
        url('Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('AvenirLTStd-Light.woff2') format('woff2'),
        url('AvenirLTStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('AvenirLTStd-Roman.woff2') format('woff2'),
        url('AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('AvenirLTStd-Book.woff2') format('woff2'),
        url('AvenirLTStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

