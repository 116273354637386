@media (min-width: 1200px){
	/* custom container bootstrap */
	.bubu-logo {
		/* max-width: 100%; */
	}
		.bubu-title {
			max-width: 100%;
		}
	.container {
	    width: 960px;
	}

	.owl-nav {
		position: absolute;
		bottom: 10% !important;
		right: 10% !important;
	}

	.bubu-logo {
		position: absolute;
		width: 15%;
		left: 10%;
			top: 20%;
		-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
			z-index: 2;

	}

		.bubu-title {
			position: absolute;
			width: 40%;
			left: 10%;
			top: 37%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
			z-index: 2;
	
		}

		.bubu-subtitle {
			position: absolute;
			width: 50%;
			left: 10%;
			top: 47%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
			z-index: 2;

		}

		.bubu-desc {
			position: absolute;
			width: 70%;
			left: 10%;
			color: #fff;
			top: 57%;
			font-weight: 400;
			font-size: 20px;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
			z-index: 2;
	
		}
	
	.ggg-main {
		position: absolute;
		left: -10%;
		bottom: 0;
		/* transform: translateX(-15%), translateY(-15%); */
		width: 50%;
		height: 50%;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s;
		z-index: 2;
	}
	
	.ggg-main {
		object-position: left;
		max-height: max-content;
	}

	.ggg-logo {
		position: absolute;
		left: 4%;
		top: 25%;
		/* transform: translateX(-15%), translateY(-15%); */
		width: 20%;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s;
	}

		.ggg-embrasing {
			position: absolute;
			right: 4%;
			top: 26%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 50%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
			z-index: 3;
		}

	.ggg-karakter {
			filter: grayscale(100%) contrast(1);
			opacity: 0.7;
		position: absolute;
		right: 0;
		bottom: 0;
		/* transform: translateX(-15%), translateY(-15%); */
		width: 30%;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s;
		z-index: 1;
	}

	.ggg-text-1 {
		text-align: right;
		position: absolute;
		right: 5.5%;
		bottom: 22%;
		/* transform: translateX(-15%), translateY(-15%); */
		width: 55%;
		font-size: 20px;
		color: #fff;
		font-weight: 500;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s;
		z-index: 4;
	}

	.ggg-text-2 {
		text-align: right;
		position: absolute;
		right: 5.5%;
		bottom: 23%;
		/* transform: translateX(-15%), translateY(-15%); */
		width: 50%;
		color: #fff;
		font-size: 20px;
		font-weight: 500;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s;
		z-index: 4;
	}
		.compass-image-logo-6 {
			position: absolute;
			left: -5%;
			bottom: -10%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 15%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.compass-image-logo-1{
			position: absolute;
			right: 0;
			top: 16%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 10%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}
	
		.compass-image-logo-2 {
			position: absolute;
			left: 4%;
			top: 18%;
			/* transform: translateY(-50%); */
			width: 40%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}
	
		.compass-image-logo-3 {
			position: absolute;
			left: 60%;
			top: 30%;
			/* transform: translateX(-20%), translateY(-30%); */
			width: 15%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}
	
		.compass-image-logo-4 {
			position: absolute;
			left: 58%;
			top: 40%;
			/* transform: translateY(-50%); */
			width: 15%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}
	
		.compass-image-logo-5 {
			position: absolute;
			left: 60%;
			top: 50%;
			/* transform: translateX(-20%), translateY(-30%); */
			width: 15%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.concave-text-1 {
			position: absolute;
			left: 4%;
			bottom: 15%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 52%;
			font-size: 20px;
			color: #333;
			font-weight: 500;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.concave-text-2 {
			position: absolute;
			left: 4%;
			bottom: 19%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 50%;
			color: #333;
			font-size: 20px;
			font-weight: 500;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.concave-image-logo-1 {
			position: absolute;
			left: 3.5%;
			top: 20%;
			width: 10%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.concave-image-ozil {
			position: absolute;
			right: -100px;
			top: 100px;
			height: 100%;
			object-fit: contain;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.overlay {
			display: none;
		}

		.concave-image-logo-2 {
			position: absolute;
			left: 3.5%;
			top: 30%;
			width: 30%;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.concave-text-1 {
			position: absolute;
			left: 4%;
			bottom: 8%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 55%;
			font-size: 20px;
			/* font-weight: 700; */
			color: #555;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.compass-text-1 {
			position: absolute;
			left: 4%;
			bottom: 25%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 52%;
			font-size: 20px;
			color: #fff;
			font-weight: 500;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}

		.compass-text-2 {
			position: absolute;
			left: 4%;
			bottom: 33%;
			/* transform: translateX(-15%), translateY(-15%); */
			width: 50%;
			color: #fff;
			font-size: 20px;
			font-weight: 500;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}
}
@media screen and  (max-width: 1366px){
	.bubu-logo{
		max-width: '600px';
	}
	.content-par-people-desc-sec-in{
		padding-right: 148px;
	}
	.people-avatar-img-full{
		width: 33%;
		left: 24%;
	}
	.people-avatar-img-full.people-avatar-img-full2{
		width: 38%;
		left: 22%;
	}
	h1.text-slider-people-tag-title{
		font-size: 43px;
	}
	h2.heading-title-name-of-poeple-s{
		font-size: 43px;
	}
	.item-media-box-rectangle-square-in{
		height: 255px;
	}
}
@media screen and  (max-width: 1200px){
	.content-par-people-desc-sec-in {
		padding-right: 80px;
		padding-left: 120px;
	}
	ul.main-menu-top-right li{
		margin-left: 18px;
	}
	.item-media-box-rectangle-square-in {
		height: 225px;
	}
	h3.title-info-media-text-bar{
		width: 60%;
	}
}
@media screen and  (max-width: 1120px){

	ul.main-menu-top-right li{
		font-size: 15px;
	}
}
@media screen and  (max-width: 1024px){
	
}
@media screen and  (max-width: 991px){
	.wrap-main-menu-top-right{
		display: none;
	}
	.bt-menu-burger-mobile{
		display: block;
	}
	.container-slider-home-text-info{
		width: 90%;
	}
	.item-logo-our-ventures{
		width: 30%;
	}
	.item-bubu-division-full-logo{
		float: none;
		width: 100%;
		height: 450px;
	}
	.item-bubu-division-full-logo-icon-in{
		text-align: center;
	}
	.item-bubu-division-full-desc{
		float: none;
		width: 100%;
		height: 100%;
		padding-top: 60px;
		padding-bottom: 60px;
		display: block;
	}
	h1.heading-title-bubu-division-full{
		font-size: 44px;
	}
	.content-item-scroll-division-info{
		padding-right: 70px;
	}
	h4.heading-subtitle-bubu-division-full{
		font-size: 16px;
		line-height: 26px;
	}
	.container-item-bubu-division-full-desc{
		width: 90%;
	}
	.content-item-scroll-division-info-padding {
		padding-left: 80px;
	}
	h2.heading-title-our-ventures{
		font-size: 37px;
	}
	a.bt-term-of-service-privacy-policy{
		font-size: 15px;
	}
	h4.bubutagmeta{
		font-size: 15px;
	}
	.copyright-bar-bot{
		font-size: 14px;
		text-align: center;
	}
	.wrap-social-media-footer {
		position: relative;
		top: 0;
		right: 0px;
		margin-bottom: 20px;
	}
	ul.social-media-footer{
		text-align: center;
	}
	.wrap-term-of-service-sec {
		padding-top: 20px;
		padding-bottom: 40px;
	}
	h3.heading-title-text-slider-home1{
		font-size: 13px;
	}
	h2.heading-title-text-slider-home {
		font-size: 43px;
		line-height: 42px;
	}
	p.par-text-desc-slider-home {
		font-size: 16px;
		margin-bottom: 20px;
	}
	.emblem-bubu25{
		width: 55px;
	}
	.snapchat-icon-slider{
		width: 87px;
	}
	.par-text-desc-slider-home-cus-padding2 {
		padding-left: 47px;
	}
	.info-bubu25-slide-section-logo{
		width: 134px;
		float: none;
		margin-bottom: 20px;
	}
	.info-bubu25-slide-section-text{
		margin-left: 0px;
		padding-left: 0;
	}
	.bt-nav-slider-home{
		right: 20px;
	}
	.item-slider-people-info-section-left{
		float: none;
		width: 100%;
		height: 100%;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.item-slider-people-info-section-right{
		float: none;
		width: 100%;
		height: auto;
		display: block;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.item-slider-people-info-section-right-in{
		display: block;
	}
	.wrap-section-people-slider-full {
		padding-top: 80px;
	}
	.text-slider-people-tag-title br{
		display: none !important;
	}
	.people-section{
		overflow: auto;
	}
	.people-avatar-img-full{
		left: 0;
		right: 0;
		width: 70%;
		margin: auto;
		position: relative;
	}
	.people-avatar-img-full.people-avatar-img-full2{
		left: 0;
		right: 0;
		width: 70%;
		margin: auto;
		position: relative;
	}
	.section-people-slider-full .center .people-avatar-img-full{
		-webkit-animation-name: slideInRight;
		-webkit-animation-duration: 1.2s;
		-moz-animation-name: faslideDoRight;
		-moz-animation-duration: 1.2s;
		-ms-animation-name: slideInRight;
		-ms-animation-duration: 1.2s;
		-o-animation-name: slideInRight;
		-o-animation-duration: 1.2s;
		animation-name: slideInRight;
		animation-duration: 1.2s;
	}
	.container-item-slider-people-info-sec-right-c{
		width: 80%;
	}
	.bt-nav-slider-home2 {
		bottom: initial;
		top: 30%;
		width: 100%;
		right: 0;
	}
	.bt-nav-slider-home2 .nav-btn-slide-home-prev-slide{
		position: absolute;
		left: 20px;
	}
	.bt-nav-slider-home2 .nav-btn-slide-home-next-slide{
		position: absolute;
		right: 20px;
	}
	.custom-padding-contact .col-lg-6, .col-lg-12 {
		padding-left: 15px;
		padding-right: 15px;
	}
	.padding-contac-us-form-sec{
		padding-left: 5px;
	}
	.info-desc-sec-text-contact-us{
		margin-bottom: 30px;
	}
	.tab-mobile-media-item{
		display: block;
	}
	ul.item-tab-media-content{
		display: none;
	}
	.wrap-tab-section-menu-left-media{
		float: none;
		width: 100%;
		margin-bottom: 30px;
	}
	.wrap-tab-content-section-menu-right-media{
		float: none;
		width: 100%;
	}
	.item-media-box-rectangle-square{
		display: block;
		padding-left: 0;
	}
	.item-media-box-rectangle{
		width: 100%;
	}
	.item-media-box-square{
		width: 100%;
	}
	.item-media-box-rectangle-square-in {
		height: 450px;
	}
	.wrap-main-menu-logo-top.wrap-main-menu-logo-top-fixed{
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.box-modal-form-info-left{
		padding-left: 20px;
		padding-right: 20px;
	}
	.wrap-bt-explore-bubu-division{
		text-align: center;
		padding-left: 0;
	}
	.container-cus-celebration-bubu{
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 130px;
	}
	.emblem-bubu25-society {
		right: 29px;
		top: 99px;
		width: 100px;
	}
	h2.heading-title-bubu-celebration-txt{
		font-size: 35px;
	}
	h4.heading-title-bubu-celebration-txt2 {
		font-size: 15px;
		letter-spacing: 2px;
	}
	h4.desc-bubu25-celebration{
		font-size: 16px;
	}
	a.bt-re-connect-bubu25 {
		font-size: 13px;
		padding-top: 6px;
		padding-bottom: 5px;
	}
	.bubu25-society-list{
		width: 96%;
	}
}
@media screen and  (max-width: 768px){
	.owl-nav {
		position: absolute;
		bottom: 10% !important;
		right: 10% !important;
	}

	.item-bubu-division-full-logo-icon-in{
		width: 35%;
	}
	.item-bubu-division-full-logo{
		height: 380px;
	}
	.item-logo-our-ventures {
		width: 49%;
	}
	.main-logo-top{
		width: 120px;
	}
	.wrap-main-menu-logo-top{
		padding-left: 20px;
		padding-right: 20px;
	}
	.wrap-our-Ventures-logo{
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.wrap-text-slider-people-tag-title {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
	.container-item-slider-people-info-sec-right-c{
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
	.textarea-f-connect-us-i{
		width: 100%;
	}
	.container-contact-us-section{
		padding-left: 20px;
		padding-right: 20px;
	}
	h2.heading-title-contact-us-form{
		font-size: 26px;
		margin-bottom: 15px;
	}
	.info-desc-sec-text-contact-us h4{
		font-size: 15px;
	}
	.contact-info-email-sec h2{
		font-size: 24px;
	}
	.contact-info-email-sec h4{
		font-size: 16px;
	}
	.item-input-form-connect-with-us-title{
		width: 127px;
		font-size: 15px;
	}
	input.input-f-connect-us-i{
		width: 100%;
	}
	.item-input-form-connect-with-us-form{
		margin-left: 130px;
	}
	h1.big-title-popup-bubu25-info{
		font-size: 19px;
	}
	.box-modal-form-info-right{
		padding-left: 20px;
		padding-right: 20px;
		width: 65%;
	}
	.box-modal-form-info-left{
		width: 35%;
	}
	.item-input-t-name-reg-f{
		width: 100%;
	}
	.item-bubu-celeb-logo {
		width: 100px;
	}
	.item-bubu-celeb-text{
		margin-left: 100px;
	}
	.img-emblem-bubu-pop {
		left: 4px;
		top: 0;
		width: 39px;
	}
	
}
@media screen and  (max-width: 640px){
	.item-media-box-rectangle-square-in {
		height: 250px;
	}
	h3.title-info-media-text-bar{
		font-size: 12px;
		padding-left: 13px;
	}
	.info-media-text-by-in{
		font-size: 12px;
	}
	.overlay-info-media-text-bar{
		height: 65px;
	}
	.social-media-per-division {
		position: relative;
		bottom: 0px;
		right: 0;
		text-align: center;
		margin-top: 20px;
	}
	.content-item-scroll-division-info-in{
		padding-right: 0;
	}
	.wrap-bt-submit-form-reg-bubu25-p{
		margin-top: 35px;
	}
}
@media screen and  (max-width: 600px){
}
@media screen and  (max-width: 480px){
	h2.heading-title-text-slider-home {
		font-size: 28px;
		line-height: 30px;
	}
	.emblem-bubu25{
		right: 20px;
		top: 17%;
	}
	h2.heading-title-our-ventures {
		font-size: 24px;
	}
	.item-logo-our-ventures-in{
		width: 75%;
	}
	h1.heading-title-bubu-division-full {
		font-size: 35px;
	}
	h1.text-slider-people-tag-title {
		font-size: 25px;
	}
	.item-slider-people-info-section-left{
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.item-slider-people-info-section-right{
		padding-top: 30px;
	}
	h2.heading-title-name-of-poeple-s {
		font-size: 25px;
	}
	h4.subtitle-name-of-people-s{
		font-size: 16px;
		margin-top: 0;
	}
	.content-par-people-desc-sec-in {
		padding-left: 60px;
	}
	.content-par-people-desc-sec-in p{
		font-size: 16px;
	}
	.wrap-tab-media-content-section{
		padding-bottom: 40px;
	}
	h3.title-heading-page-media-tab{
		font-size: 21px;
		margin-bottom: 15px;
	}
	.wrap-section-contact-us-full{
		padding-top: 105px;
	}
	h2.heading-title-contact-us-form{
		font-size: 21px;
	}
	.contact-info-email-sec h2{
		font-size: 21px;
	}
	.contact-info-email-sec h4{
		font-size: 13px;
	}
	.info-desc-sec-text-contact-us h4 {
		font-size: 14px;
		line-height: 23px;
	}
	.info-desc-sec-text-contact-us {
		margin-bottom: 10px;
	}
	.section-text-contact-info-detail{
		margin-bottom: 25px;
	}
	.item-input-form-connect-with-us{
		margin-bottom: 10px;
	}
	.wrap-input-form-connect-with-us{
		margin-bottom: 35px;
	}
	.item-input-form-connect-with-us-title{
		font-size: 13px;
	}
	.bt-submit-connect-us-cta{
		font-size: 10px;
	}
	h4.head-title-name-input-r{
		font-size: 13px;
		letter-spacing: 0.6px;
	}
	.i-input-t-name-r{
		font-size: 13px;
	}
	.i-input-t-name-r2{
		font-size: 13px;
	}
	.bt-submit-form-reg-bubu25-p{
		font-size: 13px;
	}
	.container-cus-celebration-bubu{
		padding-top: 142px;
	}
	h2.heading-title-bubu-celebration-txt {
		font-size: 19px;
	}
	h4.heading-title-bubu-celebration-txt2{
		font-size: 13px;
	}
	h4.desc-bubu25-celebration {
		font-size: 15px;
	}
	.container-cus-celebration-bubu{
		padding-top: 175px;
	}
}
@media screen and  (max-width: 420px){
	h1.text-slider-people-tag-title {
		font-size: 22px;
	}
	h2.heading-title-name-of-poeple-s {
		font-size: 22px;
	}
	.content-par-people-desc-sec-in {
		padding-left: 50px;
		padding-right: 60px;
	}
	.item-media-box-rectangle-square-in {
		height: 205px;
	}
	a.bt-show-more-media-box{
		font-size: 10px;
	}
	.wrap-bt-show-more-media-box{
		padding-top: 30px;
	}
}
@media screen and  (max-width: 360px){
}
@media screen and  (max-width: 320px){
}